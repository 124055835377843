<template>
  <div class="banner">
    <img style="width:100%" src="https://beinabncc.oss-cn-beijing.aliyuncs.com/News/cf258f53f7f61ba2b7dc8c41ca92c49f.png" alt="">
  </div>
  <div class="ly_news_cb_main">
    <div class="ly_news_cb_title">用卡说明</div>
    <p>1、此卡由BNCC出品，拥有唯一的编码，经激活后有效，本卡为一次性使用，使用后此卡将作废。 </p>
    <p>2、本卡不记名、不挂失、不兑现，请再卡片有效期内兑换使用，过期视为放弃提现。</p>

    <p>3、签收时需当面验视，因提货人原因导致的配送失败或货物损坏，不予补发。</p>

    <p>高山茶是对产自海拔较高山区的茶的统称。高山茶新销肥壮、色泽翠绿、茸毛多、节间长、鲜嫩度好。具有瘦身、美容等功效。</p>
    <span>保存方法：</span>
    <p>保存时应确保干燥、避光、防潮、防晒、低温。</p>
    <p>短期内（2个月左右）饮用，冷藏即可。超过半年左右不打算取出引用的，则可以考虑将茶叶采用冷冻储藏。</p>
    <p>有任何疑问请点击下方客服进行咨询。</p>
  </div>
  <footer>
    <ul class="footer-nav">
      <li class="footer-li">
        <a
                href="https://tb.53kf.com/code/client/a7c96e4ceb10e87d6fc792aec24104e05/3"
        >
          <!-- <img src="https://www.bzwz.com/static/m/images/icon52.png" alt="" /> -->
          <img src="@/assets/img/common/icon52.png" alt="" />
          客服
        </a>
      </li>

      <li class="footer-li button download"  style="background-color: #df0024">
        <router-link :to="{ name: 'Extractinter'}">
          <div>
            <a href="javascript:;"> 前往兑换 </a>
          </div>

        </router-link>
<!--        <a href="javascript:;"> 前往兑换 </a>-->
      </li>
    </ul>
  </footer>
</template>

<script lang="ts">
  import {
    defineComponent
  } from "vue";
  import { useRouter } from "vue-router";
  export default defineComponent({
    name: "StrAppraisal",
    setup() {
      const router = useRouter();
      function onBack() {
        router.back()
      }
      return {
        onBack
      };
    },
  });
</script>


<style lang="scss" scoped>
  .ly_news_cb_main {
    padding: 15px;
    font-size: 0.42667rem;
    line-height: 0.66667rem;
    color: #666;
    padding-bottom: 1.33333rem;
  }

  p {
    margin: 1em 0;
    word-break: break-word;
    text-indent: 20px;
  }

  .ly_news_cb_title {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: #444;
  }

  .ly_news_cb_hot_so {
    font-weight: 600;
    color: #444;
    font-size: 18px;
  }

  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    border-top: 1px solid #f4f4f4;
    font-size: 14px;
    color: #666;
    background-color: #fff;
    z-index: 100;
  }
  footer a {
    font-size: 14px;
    color: #666;
    display: block;
  }

  .footer-li {
    float: left;
    width: 17%;
    text-align: center;
    position: relative;
  }
  .footer-li img {
    display: block;
    width: 19.5px;
    height: 19.5px;
    margin: 5px auto;
  }

  .footer-li .active-status {
    position: absolute;
    display: none;
    left: 50%;
    margin-left: -9.75px;
    top: 0;
    z-index: 10;
    background-color: #fff;
  }

  .footer-nav .active a {
    color: #df0024;
  }

  .footer-nav .active .active-status {
    display: block;
  }

  footer .button {
    width: 110px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background-color: #e7c527;
    color: #fff;
    border-radius: 36px;
    margin-top: 8px;
    margin-right: 10px;
  }
  footer .button a {
    color: #fff;
  }
  .download {
    float: right;
  }
</style>